import React, {useEffect, useState} from 'react'
import RoomAPI from "../../../RoomAPI";
import "./styles.css"
import {GameMode, Maturity, PromptPack} from "../../../types2";
import {GameSettings, Room, RoomMeta} from "../../../types";
import {useAppDispatch, useAppSelector} from "../../../redux/store";
import {updateError} from "../../../redux/slices/meta";
import {useUser} from "../auth/is_loggedin";
import RadioButtons, {YesNoRadioButtons} from "../../common/radio-buttons/radio-buttons";
import FullPicker from "../../common/picker/full-picker";
import MenuFormPage from "../menu-page/menu-form-page";
import {useTranslation} from 'react-i18next';
import CustomCheckButtons from "../../common/check-buttons/custom-check-buttons";
import {useAuth0} from '@auth0/auth0-react';

type Props = {
    onCreate: (room: Room) => void
    gameMode: GameMode
}


const GameSettingsComponent = ({onCreate, gameMode}: Props) => {

    const selectPlayerDetails = useAppSelector(state => state.menu.playerDetails)
    const defaultSettings = useAppSelector(state => state.meta.settings)

    const dispatch = useAppDispatch()

    const {t} = useTranslation(['game-settings', 'common'])

    const {isAuthenticated, getAccessTokenSilently} = useAuth0()

    const user = useUser()

    // const [lobbyName, setLobbyName] = useState("")
    // const [lobbyDescription, setLobbyDescription] = useState("")

    const [writeDuration, setWriteDuration] = useState(180)

    const [quickStartGuide, setQuickStartGuide] = useState(false)
    const [maturity, setMaturity] = useState(Maturity.ALL_AGES)
    // const [accessibility, setAccessibility] = useState(false)
    const [hideAuthors, setHideAuthors] = useState(false)

    const [moderation, setModeration] = useState(false)


    const [numCharactersSubmit, setNumCharactersSubmit] = useState(5)
    const [numCharactersMin, setNumCharactersMin] = useState(2)
    const [numCharactersMax, setNumCharactersMax] = useState(2)

    const [numSwitches, setNumSwitches] = useState(3)
    const [audiences, setAudiences] = useState(true)

    // const [promptPack, setPromptPack] = useState<PromptPack>(PromptPack.BASIC)

    const [forcedPrompts, setForcedPrompts] = useState<boolean>(false)

    const [maxPlayers, setMaxPlayers] = useState(16)
    const [submitted, setSubmitted] = useState(false)

    const [showAdvanced, setShowAdvanced] = useState(false)
    const [selfShuffle, setSelfShuffle] = useState(false)
    const [multiPrompt, setMultiPrompt] = useState(true)

    const [extraReadingTime, setExtraReadingTime] = useState(true)

    const [numOtpCharacters, setNumOtpCharacters] = useState(0)

    const [fasterPhases, setFasterPhases] = useState(false)
    const [characterMaturity, setCharacterMaturity] = useState(gameMode === GameMode.self_insert)

    const [prompts, setPrompts] = useState<Set<PromptPack>>(new Set([PromptPack.BASIC]))


    function handleNumCharacters(num: number) {
        setNumCharactersMin(num)
        setNumCharactersMax(num)
    }

    useEffect(() => {
        let gameSettings = localStorage.getItem("gameSettings")
        if (gameSettings) {
            let parse = JSON.parse(gameSettings);
            if (parse.write_duration !== undefined) {
                setWriteDuration(parse.write_duration)
            }
            if (parse.show_instructions !== undefined) {
                setQuickStartGuide(parse.show_instructions)
            }
            if (parse.maturity !== undefined) {
                if (gameMode === GameMode.solo_mode) {
                    setMaturity(selectPlayerDetails?.meta.mature ? Maturity.MATURE : Maturity.ALL_AGES)
                } else {
                    setMaturity(selectPlayerDetails?.meta.mature ? parse.maturity : Maturity.ALL_AGES)

                }
            }
            // if (parse.accessibility !== undefined) {
            //     setAccessibility(parse.accessibility)
            //
            // }
            if (parse.hide_authors !== undefined) {
                setHideAuthors(parse.hide_authors)
            }
            if (parse.max_players !== undefined) {
                setMaxPlayers(parse.max_players)
            }
            if (parse.moderation !== undefined) {
                setModeration(parse.moderation)
            }

            if (parse.self_shuffle !== undefined) {
                setSelfShuffle(parse.self_shuffle)
            }

            if (parse.multi_prompt !== undefined) {
                setMultiPrompt(parse.multi_prompt)
            }

            if (parse.num_characters_min !== undefined) {
                setNumCharactersMin(parse.num_characters_min)
            }

            if (parse.num_characters_min !== undefined) {
                setNumCharactersMax(parse.num_characters_max)
            }

            if (parse.num_characters_submit !== undefined) {
                setNumCharactersSubmit(parse.num_characters_submit)
            }

            if (parse.num_switches !== undefined) {
                setNumSwitches(parse.num_switches)
            }

            if (parse.audiences !== undefined) {
                setAudiences(parse.audiences)
            }

            if (parse.no_extra_reading_time !== undefined) {
                setExtraReadingTime(!parse.extra_reading_time)
            }

            if (parse.num_otp_characters !== undefined) {
                setNumOtpCharacters(parse.num_otp_characters)
            }

            if (parse.faster_phases !== undefined) {
                setFasterPhases(parse.faster_phases)
            }

            if (parse.forced_prompts !== undefined) {
                setForcedPrompts(parse.forced_prompts || gameMode === GameMode.solo_mode)
            }

            // if (parse.prompt_packs !== undefined) {
            //     setPromptPack(parse.prompt_packs)
            // }
        }

        if (defaultSettings && gameMode !== GameMode.solo_mode) {
            setFasterPhases(defaultSettings.faster_phases)
        }


    }, [defaultSettings, gameMode, selectPlayerDetails?.meta.mature])


    function soloMode() {
        return gameMode === GameMode.solo_mode
    }

    const maturePrompts = [
        {label: t('prompts.basic', {ns: 'common'}), value: PromptPack.BASIC},
        {label: t('prompts.hardcore', {ns: 'common'}), value: PromptPack.HARDCORE},
        {label: t('prompts.drama', {ns: 'common'}), value: PromptPack.DRAMA},
        {label: t('prompts.art', {ns: 'common'}), value: PromptPack.ART},
        {label: t('prompts.fantasy', {ns: 'common'}), value: PromptPack.FANTASY},
        {label: t('prompts.fluff', {ns: 'common'}), value: PromptPack.FLUFF},
        {label: t('prompts.gamers', {ns: 'common'}), value: PromptPack.GAMERS},
        {label: t('prompts.hungry', {ns: 'common'}), value: PromptPack.HUNGRY},
        {label: t('prompts.phrases', {ns: 'common'}), value: PromptPack.PHRASES},
    ]

    async function submit() {
        if (!selectPlayerDetails) {
            alert("WHERE IS YOUR PLAYER?")
            return
        }
        try {
            setSubmitted(true)

            let roomSettings: GameSettings = {
                num_characters_submit: numCharactersSubmit, // number to submit
                min_characters: numCharactersMin, // number to select
                max_characters: numCharactersMax, // number to select

                write_duration: writeDuration,
                show_instructions: quickStartGuide,
                maturity: maturity,
                // accessibility: accessibility,
                hide_authors: hideAuthors,
                max_players: maxPlayers,
                game_mode: gameMode,
                moderation: moderation,

                num_switches: numSwitches,
                self_shuffle: selfShuffle,
                audiences: audiences,
                public_lobby: false,
                multi_prompt: multiPrompt,
                prompt_packs: Array.from(prompts),
                no_extra_reading_time: !extraReadingTime,
                num_otp_characters: numOtpCharacters,
                faster_phases: fasterPhases,
                forced_prompts: forcedPrompts || soloMode(),
                character_maturity: characterMaturity,
                bookend: false
            }
            let roomMeta: RoomMeta = {
                description: "", name: ""
            }

            let token;
            if (isAuthenticated) {
                token = await getAccessTokenSilently()
            }

            let room = await RoomAPI.CreateRoom(roomSettings, roomMeta, selectPlayerDetails, user?.auth0Id, token).catch(reason => {
                dispatch(updateError(reason.response.data))
                setSubmitted(false)
            })


            if (room && room.host) {
                onCreate(room)
                localStorage.setItem("gameSettings", JSON.stringify(roomSettings))
                localStorage.setItem("roomCode", room.code)
            }


        } catch (err) {
            alert("something bad happened: " + err)
            setSubmitted(false)
        }
    }


    // useEffect(() => {
    //     if (gameMode === GameMode.self_insert) {
    //         setCharacterMaturity(false)
    //     }
    //
    // }, [gameMode, maturity])

    useEffect(() => {
        if (maturity === Maturity.ALL_AGES) {
            setCharacterMaturity(false)
            setPrompts(p => {
                let s = new Set(p)
                s.delete(PromptPack.HARDCORE)
                return s
            })
        } else if (maturity === Maturity.MATURE) {

        }
    }, [maturity])

    function handleMaturity(v: boolean) {
        setMaturity(v ? 1 : 0)
    }

    return <MenuFormPage submit={submit} titles={[t('headers.default')]} subHeaders={[t('subheaders.default')]}
                         submitText={t('footers.createGame')}
                         ready={!submitted}>
        <div className={"create-game"}>

            {/* QUICK START GUIDE*/}
            <YesNoRadioButtons label={t('body.quickStart')} subLabel={t('body.quickStart2')} value={quickStartGuide}
                               onSelect={setQuickStartGuide}/>


            {/* TIME TO WRITE */}
            <FullPicker label={t('body.timeToWrite')} subLabel={t('body.timeToWrite2')} value={writeDuration}
                        onSelect={setWriteDuration} options={
                [
                    {value: 10, labels: [t('speeds.sonic', {ns: 'common'}), "10 sec"]},
                    {value: 60, labels: [t('speeds.lightning', {ns: 'common'}), "1 min"]},
                    {value: 180, labels: [t('speeds.frantic', {ns: 'common'}), "3 min"]},
                    {value: 300, labels: [t('speeds.brisk', {ns: 'common'}), "5 min"]},
                    {value: 600, labels: [t('speeds.leisurely', {ns: 'common'}), "10 min"]}]}/>

            {selectPlayerDetails?.meta.mature && gameMode !== GameMode.solo_mode &&
                <YesNoRadioButtons label={t('body.maturity')}
                                   subLabel={soloMode() ? t('body.maturity2Solo') : undefined}
                                   value={maturity === 1} yesLabel={t('label.allAges', {ns: 'common'})}
                                   noLabel={t('label.mature', {ns: 'common'})}
                                   onSelect={handleMaturity}/>}


            {/* ACCESSIBILITY */}
            {/*<YesNoRadioButtons label={t('body.accessible')} value={accessibility}*/}
            {/*                   onSelect={setAccessibility}/>*/}

            {/* Prompts */}
            {<CustomCheckButtons yesLabel={t('body.forcedPromptsForced')} noLabel={t('body.forcedPromptsOptional')}
                                 multiPromptsLabel={t('body.multiPrompt')}
                                 forcedPrompts={forcedPrompts}
                                 hideForcedPrompts={soloMode()}
                                 multiPrompts={multiPrompt}
                                 onMultiPrompts={setMultiPrompt}
                                 yesMulti={t('body.multiPromptYes')}
                                 noMulti={t('body.multiPromptNo')}
                                 onForcePrompt={setForcedPrompts}
                                 selected={prompts}
                                 disabled={val => (maturity === 1 && val === PromptPack.HARDCORE) || (!user?.premium && val > PromptPack.HARDCORE)}
                                 onSelect={(val, checked) => {
                                     setPrompts(p => {
                                         let s = new Set(p)
                                         if (checked) {
                                             s.add(val)
                                         } else {
                                             s.delete(val)
                                         }
                                         return s
                                     })
                                 }} label={t('body.promptPack')} subLabel={t('body.promptPack2')}
                                 options={maturePrompts}/>}


            {/* MODERATION*/}
            {!soloMode() && <YesNoRadioButtons label={t('body.moderation')} subLabel={t('body.moderation2')}
                                               value={moderation}
                                               radioSubheader={""}
                                               onSelect={setModeration}/>}

            {!showAdvanced &&
                <button className={"advanced-button cursor-pointer underline"}
                        onClick={() => setShowAdvanced(true)}>[{t('body.showAdvanced')}]</button>}


            {showAdvanced && <>

                <button className={"advanced-button cursor-pointer underline"}
                        onClick={() => setShowAdvanced(false)}>[{t('body.hideAdvanced')}]
                </button>

                {/*Character Maturity*/}
                {!soloMode() && <YesNoRadioButtons label={t('body.charMaturity')} disabled={maturity === 1}
                                                   yesLabel={"yes"}
                                                   noLabel={"no"}
                                                   subLabel={"for your comfort levels"}
                                                   value={characterMaturity}
                                                   onSelect={setCharacterMaturity}/>}

                {/* MAX PLAYERS */}
                {!soloMode() && <FullPicker label={t('body.maxPlayers')} value={maxPlayers}
                                            onSelect={setMaxPlayers} options={
                    [
                        {value: 3},
                        {value: 6},
                        {value: 9},
                        {value: 12},
                        {value: 16}]}/>}


                {/*    NUMBER OF SWITCHES */}
                <FullPicker label={t('body.numParts')} subLabel={t('body.numParts2')} value={numSwitches}
                            onSelect={setNumSwitches} options={
                    [
                        {value: 3},
                        {value: 4},
                        {value: 5},
                        {value: 6},
                        {value: 7},
                        {value: 8},
                        {value: 9},
                        {value: 10}]}/>

                {/* NUMBER OF CHARACTERS TO SELECT*/}
                {/*<FullPicker label={t('body.numCharactersSelect')} subLabel={t('body.numCharactersSelect2')}*/}
                {/*            value={Math.max(numCharactersMin, numCharactersMax)}*/}
                {/*            onSelect={handleNumCharacters} options={*/}
                {/*    [*/}
                {/*        {value: 2},*/}
                {/*        {value: 3}]}/>*/}

                <RadioButtons label={t('body.numCharactersSelect')} value={Math.max(numCharactersMin, numCharactersMax)}
                              options={[
                                  {value: 2},
                                  {value: 3}]} onSelect={val => handleNumCharacters(parseInt(val))}
                              subLabel={t('body.numCharactersSelect2')}/>


                {/* SELF SHUFFLE*/}
                {!soloMode() && gameMode !== GameMode.otp &&
                    <YesNoRadioButtons label={t('body.selfShuffle')} subLabel={t('body.selfShuffle2')}
                                       value={selfShuffle}
                                       onSelect={setSelfShuffle}/>}

                {/* SELF SHUFFLE*/}
                {soloMode() &&
                    <YesNoRadioButtons label={t('body.autoShuffle')} subLabel={t('body.autoShuffle2')}
                                       value={selfShuffle}
                                       onSelect={setSelfShuffle}/>}

                {/* Audiences */}
                {!soloMode() &&
                    <YesNoRadioButtons label={t('body.audience')} value={audiences}
                                       onSelect={setAudiences}/>}

                {/* TIME TO WRITE */}

                {/*<FullPicker label={t('body.timeToWrite')} subLabel={t('body.timeToWrite2')} value={writeDuration}*/}
                {/*            onSelect={setWriteDuration} options={*/}
                {/*    [*/}
                {/*        {value: 10, labels: ["sonic", "10 sec"]},*/}
                {/*        {value: 30, labels: ["30 second"]},*/}
                {/*        {value: 60, labels: ["lightning", "1 min"]},*/}
                {/*        {value: 120, labels: ["2 minute"]},*/}
                {/*        {value: 180, labels: ["frantic", "3 min"]},*/}
                {/*        {value: 240, labels: ["4 minute"]},*/}
                {/*        {value: 300, labels: ["brisk", "5 min"]},*/}
                {/*        {value: 360, labels: ["6 minute"]},*/}
                {/*        {value: 420, labels: ["7 minute"]},*/}
                {/*        {value: 480, labels: ["8 minute"]},*/}
                {/*        {value: 540, labels: ["9 minute"]},*/}
                {/*        {value: 600, labels: ["leisurely", "10 min"]}]}/>*/}

                {/*EXTRA READING TIME*/}
                {!soloMode() &&
                    <YesNoRadioButtons label={t('body.extraReadingTime')}
                                       value={extraReadingTime}
                                       onSelect={setExtraReadingTime}/>}

                {/*HIDE AUTHORS*/}
                {!soloMode() &&
                    <YesNoRadioButtons label={t('body.hideAuthors')}
                                       value={hideAuthors}
                                       onSelect={setHideAuthors}/>}


            </>}


        </div>
    </MenuFormPage>
}

export default GameSettingsComponent