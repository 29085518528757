import React, {MutableRefObject, useCallback, useContext, useEffect, useRef, useState} from "react";
import './styles.css'

import TextareaAutosize from 'react-textarea-autosize'
import {config, constants} from "../../../constants";
import {Character} from "../../../types";
import {useAppSelector} from "../../../redux/store";
import GamePage from "../game-page/game-page";
import {PrevSubmission} from "../../common/prev-submission/prev-submission";
import {numberString} from "../character-submit/character-submit";
import {GameMode} from "../../../types2";
import {GameContext} from "../../../contexts";
import StoryFooter from "./story-footer";
import {selectIsSolo} from "../../../redux/selectors";
import {SafeJoin} from "../../../util";
import {useTranslation} from "react-i18next";
import useInterval from "../../common/use-interval/use-interval";
import {StoryState} from "./story";

const submitInterval = 2500;

type Props = {
    timeRemainingRef: MutableRefObject<number>
    storyState?: number
}


function title(characters?: Character[]) {
    if (characters) {
        return <span className={"max-w-full"}>
            {SafeJoin(characters.filter(c => c.selected).map<React.ReactNode>(c => <span
                key={'story-character-' + c.id}
                className={"text-emphasis max-w-full break-words inline-block"}>{c.name}</span>), ' & ')}
        </span>
    } else {
        return <span>oops</span>
    }

}

const StoryTime = ({storyState, timeRemainingRef}: Props) => {
    const {submit} = useContext(GameContext)
    const {t} = useTranslation(['game', 'common'])

    const playerState = useAppSelector(state => state.player.player)
    const gameState = useAppSelector(state => state.game.game)
    const accessibility = useAppSelector(state => state.player.player?.meta.accessibility)

    const gameSettings = gameState?.settings

    const isSolo = useAppSelector(selectIsSolo);

    const story = playerState?.current_story;
    const submission = playerState?.current_submission

    const start = gameState?.submission?.reading_start ?? Number.MAX_SAFE_INTEGER
    const end = playerState?.submission?.submission_end ?? Number.MAX_SAFE_INTEGER
    const duration = end - start;

    const numSubmissions = gameSettings?.num_switches ?? 3

    const totalSeconds = Math.ceil((duration) / 1000);
    const paddedMinutes = Math.floor(totalSeconds / 60).toString().padStart(2, "0")
    const paddedSeconds = (Math.floor(totalSeconds) % 60).toString().padStart(2, "0")

    const [done, setDone] = useState(submission?.meta.done ?? false)

    const [content, setContent] = useState<string>(submission?.content ?? "")

    const submissionId = useRef<number>()
    const flagged = useRef(new Set<number>())


    function handleFlag(checked: boolean, id: number) {
        if (checked) {
            flagged.current.add(id)
        } else {
            flagged.current.delete(id)
        }
    }

    function handleDone() {
        let d = !done
        setDone(d)
        submitContent(content, d)
    }


    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setContent(e.target.value)
        setDone(false)
    }

    const submitContent = useCallback((content: string, done ?: boolean, noLoading?: boolean) => {
        if ((content !== submission?.content && submissionId.current === submission?.id) || submission?.meta.done !== done) {
            let playerAction = {
                body: {
                    submission: {
                        id: submission?.id,
                        content: content
                    },
                    flagged_submissions: Array.from(flagged.current),
                    done_submission: done
                },
                code: "story/submit",
            }
            submit(playerAction, noLoading)
        }
    }, [submission?.content, submission?.id, submission?.meta.done, submit])

    useEffect(() => {
        if (storyState === StoryState.SAVING && submission?.id === submissionId.current) {
            submitContent(content)
        }
    }, [content, storyState, submission?.id, submitContent])

    useEffect(() => {
        if (submission?.id !== submissionId.current) {
            setContent(submission?.content ?? "")
            // slam over when we were not on a submission previously
        }

        submissionId.current = submission?.id

    }, [submission?.content, submission?.id, submissionId])


    useInterval(() => submitContent(content, undefined, true), submitInterval)

    function checkPaste(e: React.ClipboardEvent<HTMLTextAreaElement>) {
        if (!config.longPaste && e.clipboardData.getData("text").length > 200) {
            e.preventDefault()
        }
    }


    function label() {
        return submission?.index === 0 ? t('storyTime.body.startingStory') :
            submission?.index === (numSubmissions - 1) ? t('storyTime.body.endStory') : t('storyTime.body.middleStory')
    }

    function partMap(index: number, numSwitches: number) {
        if (index === 0) {
            return t('label.beginning', {ns: 'common'})
        } else if (index === numSwitches - 1) {
            return t('label.end', {ns: 'common'})
        } else {
            return `${t('label.part', {ns: 'common'})} ${numberString(index + 1)}`
        }
    }

    function rating() {
        if (story?.settings.mature) {
            return t('label.eighteenPlus', {ns: 'common'})
        } else {
            return t('label.allAges', {ns: 'common'})
        }
    }


    function titles() {
        const lines = [title(story?.characters)]

        const part = partMap(submission?.index as number, gameSettings?.num_switches ?? 0)

        if (!isSolo) {
            let line = t('storyTime.headers.default', {
                index: story?.meta.index as number + 1,
                part: part,
                rating: rating()
            })
            lines.push(<>{line}</>)
        } else {
            lines.push(<>{part}</>)
        }
        return lines
    }


    function submissionsList() {
        const filtered = story?.submissions?.filter(v => v.index < (submission?.index ?? 1000))
        return (filtered && filtered.length > 0) ? filtered.map<React.ReactNode>((value) =>
            <PrevSubmission playerId={playerState?.id as number} key={value.id}
                            canFlag={playerState?.id !== value.player_id}
                            submission={value} onToggle={b => handleFlag(b, value.id)}/>
        ) : ''
    }


    function subHeaders() {
        const lines = [t('storyTime.subheaders.default', {
            prefix: label(),
            minutes: paddedMinutes,
            seconds: paddedSeconds
        })]
        if (gameSettings?.game_mode === GameMode.solo_mode || gameSettings?.forced_prompts) {
            lines.push(t('storyTime.subheaders.prompt', {prompt: playerState?.current_submission?.meta.prompt}))
        }

        return lines
    }


    return <GamePage titlesJsx={titles()} subHeaders={subHeaders()}
                     footer={<StoryFooter onPlayerAction={submit} onDone={handleDone} done={done}
                                          timeRemainingRef={timeRemainingRef}/>}>
        <div
            className={`w-full flex flex-col ${submissionsList().length > 0 && 'divide-gray-drk divide-y divide-dashed divide-x-0'}`}>
            <div className={"prev-story divide-y-2 divide-gray-drk divide-dashed space-y-2"}
                 style={constants.accessibleStyle(accessibility)}>
                {submissionsList()}
            </div>
            <div className={`story-time-content-container ${submissionsList().length > 0 && 'mt-2'}`}>
                {/*{submission?.index !== 0 ? <hr className={"w-full"}/> : ''}*/}

                {/*<div contentEditable className={"bg-white normal-case"} onChange={handleChange2}>{getContent()}</div>*/}
                {/*<ContentEditable className={"bg-white normal-case"} html={content} onChange={handleChange2}/>*/}

                <TextareaAutosize minRows={4} onPaste={checkPaste}
                                  value={content}
                                  maxLength={config.maxSubmissionSize}
                                  disabled={storyState !== 1}
                                  style={constants.accessibleStyle(accessibility)}
                                  onChange={handleChange}/>
            </div>
        </div>
    </GamePage>

}

export default StoryTime